import type { LinksFunction, MetaFunction, LoaderFunctionArgs } from '@remix-run/node';
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  isRouteErrorResponse,
  useRouteError,
  useLocation,
  json,
  useLoaderData,
  useRouteLoaderData,
} from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { useHydrateAtoms } from 'jotai/utils';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { getToast } from 'remix-toast';
import { ClientOnly } from 'remix-utils/client-only';

import { Link } from './components/Link';
import { ErrorScreen } from './components/screens/ErrorScreen';
import { PublicEnv } from './environment/PublicEnv';
import { getServerPublicEnv } from './environment/getPublicEnv';
import { getAuthSession } from './features/auth/.server/session/getAuthSession';
import { AuthProvider } from './features/auth/useAuth';
import { CampaignModalProvider } from './features/client/components/CampaignModal/CampaignModalContext';
import { useConsoleLogger } from './features/console/hooks/useConsoleLogger';
import { useCustomerioTracker } from './features/customerio/hooks/useCustomerioTracker';
import { useGoogleTracker } from './features/google/hooks/useGoogleTracker';
import { loggersAtom } from './features/logging/atoms/loggers.atom';
import { useLoggerTracker } from './features/logging/hook/useLoggerTracker';
import { useMixpanelTracker } from './features/mixpanel/hooks/useMixpanelTracker';
import { MixpanelProvider } from './features/mixpanel/providers/MixpanelProvider';
import { useAppTracking } from './features/tracking/app/hooks/useAppTracking';
import { trackersAtom } from './features/tracking/core/atoms/trackers.atom';
import { useToast } from './hooks/useToast';
import stylesheet from './index.css?url';
import { QueryClientProvider } from './providers/queryClientProvider';
import 'react-toastify/dist/ReactToastify.css';

export const links: LinksFunction = () => [{ rel: 'stylesheet', href: stylesheet }];

export const meta: MetaFunction = () => [{ title: 'Ramdam Dashboard' }];

export const loader = async ({ request }: LoaderFunctionArgs) => {
  const { toast, headers } = await getToast(request);
  const { data } = await getAuthSession(request);

  return json(
    {
      tokens: { accessToken: data.accessToken },
      // Every environment variable here will be exposed to the client
      env: getServerPublicEnv(),
      baseUrl: new URL(request.url).origin,
      toast,
    },
    { headers },
  );
};

export function Layout({ children }: { children: React.ReactNode }) {
  const data = useRouteLoaderData<typeof loader>('root');
  const location = useLocation();
  const isRegistrationPage = location.pathname === '/client/signup/organization';

  return (
    <html lang="en" translate="no">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="google" content="notranslate" />

        {/* Favicons */}
        <link rel="icon" href="/favicon.ico" />
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#e7f256" />
        <meta name="msapplication-TileColor" content="#e7f256" />
        <meta name="theme-color" content="#ffffff" />
        {/* manifest.json provides metadata used when your web app is installed on a user's mobile device or desktop. See https://developers.google.com/web/fundamentals/web-app-manifest/ */}
        <link rel="manifest" href="/manifest.json" />

        {/* FONTS */}
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap" rel="stylesheet" />
        {/* END FONTS */}

        {/* twitter */}
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content="ramdam" />
        <meta name="twitter:site" content="@helloramdam" />
        <meta
          name="twitter:description"
          content="Get winning creator ads without the guesswork, using the power of AI."
        />
        {data ? <meta name="twitter:image" content={`${data.baseUrl}/XOG.png`} /> : null}

        {/* facebook / linkedin */}
        <meta property="og:type" content="website" />
        <meta property="og:title" content="ramdam" />
        <meta property="og:url" content="https://ramd.am" />
        {data ? <meta property="og:image" content={`${data.baseUrl}/linkedinOG.png`} /> : null}
        <meta
          property="og:description"
          content="Get winning creator ads without the guesswork, using the power of AI."
        />

        {/* Extra styles */}
        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />

        <Meta />
        <Links />
        {data?.env.PUBLIC_HOTJAR_ID ? (
          <script
            dangerouslySetInnerHTML={{
              __html: `(function(h,o,t,j,a,r){h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};h._hjSettings={hjid:${data.env.PUBLIC_HOTJAR_ID},hjsv:6};a=o.getElementsByTagName('head')[0];r=o.createElement('script');r.async=1;r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;a.appendChild(r);})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
            }}
          />
        ) : null}
      </head>
      <body className={isRegistrationPage ? 'registration' : ''}>
        {data && data.env ? <PublicEnv env={data.env} /> : null}
        <div id="root">{children}</div>
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return (
        <ErrorScreen title="Sorry, it seems this page doesn't exist.">
          <Link to="/">Return to the home page</Link>
        </ErrorScreen>
      );
    } else if (error.status >= 500) {
      return (
        <ErrorScreen
          title={`error_${error.status}`}
          description="Something went wrong. We were notified and are working on fixing the issue."
        />
      );
    } else {
      return <ErrorScreen title={`error_${error.status}`} description="Oops!" />;
    }
  }

  // Easier debugging if the team happens to see the error
  console.error(error);

  return (
    <ErrorScreen
      title="unknown_error"
      description="Something went wrong. We were notified and are working on fixing the issue."
    />
  );
};

export default function App() {
  const { env, tokens, toast: incomingToast } = useLoaderData<typeof loader>();
  const { logger: consoleLogger } = useConsoleLogger();
  useHydrateAtoms([[loggersAtom, [consoleLogger]]]);

  const { toast } = useToast();

  useEffect(() => {
    if (incomingToast) {
      toast(incomingToast.message, { type: incomingToast.type });
    }
  }, [toast, incomingToast]);

  return (
    <MixpanelProvider token={env.PUBLIC_MIXPANEL_TOKEN}>
      <Tracking />
      <QueryClientProvider
        // Prevent painful refetch when mocks are enabled
        refetchOnWindowFocus={import.meta.env.VITE_ENABLE_API_MOCKS !== 'true'}
      >
        <AuthProvider tokens={tokens}>
          <CampaignModalProvider>
            <Outlet />
            <ToastContainer theme="colored" />
          </CampaignModalProvider>
        </AuthProvider>
      </QueryClientProvider>
      {env.PUBLIC_ENABLE_REACT_QUERY_DEVTOOLS ? <ReactQueryDevtools /> : null}

      {/*
        Workaround to know in Cypress when hydration is done.
        Otherwise hydration errors will cause the whole page to be trashed and re-rendered while Cypress executes tests which causes flakiness.
      */}
      {import.meta.env.VITE_ENABLE_CY_HYDRATION_WORKAROUND ? (
        <ClientOnly>{() => <div id="__is_hydrated__"></div>}</ClientOnly>
      ) : null}
    </MixpanelProvider>
  );
}

function Tracking() {
  const { tracker: customerIoTracker } = useCustomerioTracker();
  const { tracker: mixpanelTracker } = useMixpanelTracker();
  const { tracker: consoleTracker } = useLoggerTracker();
  const { tracker: googleTracker } = useGoogleTracker();

  useHydrateAtoms([[trackersAtom, [consoleTracker, customerIoTracker, mixpanelTracker, googleTracker]]]);

  const { track } = useAppTracking();
  const location = useLocation();

  useEffect(() => {
    track({ type: 'pageView', path: location.pathname });
  }, [location.pathname, track]);

  return null;
}
